<template>
  <div class="root">
    <custom-header></custom-header>
    <!-- 第一屏 -->
    <div
      class="page bg-star"
      id="page1"
      @mousewheel="scrollFun"
      :style="'height:' + screenHeight + 'px'"
    >
      <div class="mask-left"></div>
      <div class="mask-right"></div>
      <!-- 首屏文字 -->
      <transition leave-active-class="titleHidden">
        <div class="show-title" v-if="screenIndex == 1">
          <p>每一个生命</p>
          <p>都值得被<span class="special-color">纪念</span></p>
          <p>安泰乐<span class="circle"></span>网上纪念馆</p>
        </div>
      </transition>
      <!-- 视频 -->
      <transition
        :enter-active-class="screenIndex == 2 ? 'fallVideo' : ''"
        leave-active-class="screenIndex == 1"
      >
        <div
          :class="[
            {
              'animate-video':
                screenIndex == 2 || screenIndex == 3 || screenIndex == 4,
            },
            'show-video',
          ]"
        >
          <div class="video-mask"></div>
          <video
            :controls="screenIndex == 3"
            autoplay
            loop
            :class="[
              {
                'animate-video':
                  screenIndex == 2 || screenIndex == 3 || screenIndex == 4,
              },
              'video',
            ]"
            v-on:canplay="canplay"
            muted
            ref="video"
          >
            <source :src="PATH_TO_MP4" type="video/mp4" />
            浏览器不支持 video 标签，建议升级浏览器。
            <!-- <source :src="PATH_TO_WEBM" type="video/webm"/>
        浏览器不支持 video 标签，建议升级浏览器。 -->
          </video>
        </div>
      </transition>
      <!-- 视频过渡第二屏 -->
      <transition
        enter-active-class="animated opacityShow"
        leave-active-class="animated opacityHidden"
      >
        <div
          class="show-mask"
          v-if="screenIndex == 2 || screenIndex == 4"
        ></div>
      </transition>
      <transition
        enter-active-class="animated bounceInUp"
        leave-active-class="animated bounceOutDown"
      >
        <div class="mask-img" v-if="screenIndex == 2">
          <p>生命纪念馆</p>
          <p class="special-color">为后人传承精神财富!</p>
          <p class="gray-color">死亡不是人生的终点 , 遗忘才是</p>
          <p class="gray-color">为后人为亲人建馆，随时记录，时刻触及</p>
          <p class="gray-color">纪念馆将承载一生的数据，世代相传</p>
        </div>
      </transition>
      <transition
        enter-active-class="animated bounceInUp"
        leave-active-class="animated bounceOutDown"
      >
        <div class="mask-img" v-if="screenIndex == 4">
          <p>爱的传承</p>
          <p class="special-color">念念不忘 ，必有回响</p>
          <p class="gray-color">爱的传承，是每一个家庭的朴素愿望与虔诚祷告</p>
          <p class="gray-color">传承，不仅为逝者，也为自己，更是为后人</p>
          <p class="gray-color">传承，让家有了永恒的生命力！</p>
        </div>
      </transition>
    </div>
    <div class="page" id="page2" :style="'height:' + screenHeight + 'px'">
      <div class="show-tab">
        <span
          v-html="videoSource"
          :style="'width: 100%;height:' + screenHeight + 'px'"
        ></span>
        <!-- src="https://atlimg.oss-cn-hangzhou.aliyuncs.com/web/home/show.png" -->
        <img
          v-if="checkedTab == 3"
          src="@/assets/images/thidr-line3.png"
          alt=""
          class="small"
          :style="'height:' + screenHeight + 'px'"
        />
         <img
          v-if="checkedTab == 2"
          src="@/assets/images/on-line1.png"
          alt=""
          class="small"
          :style="'height:' + screenHeight + 'px'"
        />
         <img
          v-if="checkedTab == 1"
          src="@/assets/images/Multiterminals.jpg"
          alt=""
          class="small"
          :style="'height:' + screenHeight + 'px'"
        />
        <div class="tab-mask"></div>
        <div class="my-tab">
          <ul>
            <li
              @click="changeTab(item.id)"
              :class="checkedTab == item.id ? 'special-color' : ''"
              v-for="(item, index) in tabs"
              :key="index"
            >
              {{ item.name }}
            </li>
          </ul>
          <div class="show-tip" v-if="checkedTab == 1">
            我们提供了丰富模版，根据自己喜好可自由选择
          </div>
          <div class="show-tip" v-else-if="checkedTab == 2">
            专属空间，不止线上。在线纪念功能可随时随地通过手机轻松记录
          </div>
          <div class="show-tip" v-else>
            突破限制，可以将线上纪念馆呈现在手机，IPAD、电视、电脑上
          </div>
        </div>
      </div>
    </div>
    <div class="page" id="page3">
      <div class="show-scrollword">
        <p>尊重生命</p>
        <p class="special-color">传承文化</p>
        <p>
          无限的数据空间，可以承载无数的文化传承，我们尊重生命，感恩世界，那些曾经的过往，也是我们及子孙后代无价的宝藏！
        </p>
      </div>
      <div class="scroll-table">
        <div class="show-scrollTitle" @click="goTimePeriod">
          <p class="title">部分纪念馆案例</p>
          <div class="scroll-more">
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="show-more">
              <span>更多</span>
            </div>
          </div>
        </div>
        <vue-seamless-scroll
          :data="hallList1"
          :class-option="optionLeft"
          class="seamless-warp2"
        >
          <ul class="item">
            <li
              v-for="(item, index) in hallList1"
              :key="index"
              @click="showPreviewModal(item)"
            >
              <img :src="item.coverUrl" alt="" />
            </li>
          </ul>
        </vue-seamless-scroll>
        <vue-seamless-scroll
          :data="hallList2"
          :class-option="optionRight"
          class="seamless-warp2"
        >
          <ul class="item">
            <li
              v-for="(item, index) in hallList2"
              :key="index"
              @click="showPreviewModal(item)"
            >
              <img :src="item.coverUrl" alt="" />
            </li>
          </ul>
        </vue-seamless-scroll>
        <vue-seamless-scroll
          :data="hallList3"
          :class-option="optionLeft"
          class="seamless-warp2"
        >
          <ul class="item">
            <li
              v-for="(item, index) in hallList3"
              :key="index"
              @click="showPreviewModal(item)"
            >
              <img :src="item.coverUrl" alt="" />
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
    <div class="page bg-image" id="page4">
      <div class="show-moretitle">
        <p>突破技术壁垒</p>
        <p class="special-color">安全、便捷、极致体验</p>
      </div>
      <div class="show-more">
        <div v-class="'wordShow'">
          <img src="@/assets/images/application.png" alt="" />
          <p class="show-subtitle">云应用</p>
          <p>应用商店，不断扩展应用库</p>
          <p>根据需要可自行安装，满足</p>
          <p>不同用户需求</p>
        </div>
        <div v-class="'wordShow'">
          <img src="@/assets/images/Deposit.png" alt="" />
          <p class="show-subtitle">私有云存储</p>
          <p>可将数据存入个人的云存储</p>
          <p>空间，保证用户数据安全。</p>
        </div>
        <div v-class="'wordShow'">
          <img src="@/assets/images/Exhibition.png" alt="" />
          <p class="show-subtitle">多端展示</p>
          <p>线上生命纪念馆可以展示在</p>
          <p>手机、电脑、TV上 &nbsp;, &nbsp;不同的</p>
          <p>应用场景可以随时切换</p>
        </div>
      </div>
      <div class="show-btn" @click="createHall">
        <p>立即创建</p>
        <!-- <p>安泰乐·网上生命记念馆</p> -->
      </div>
    </div>
    <custom-footer></custom-footer>
    <preview-modal ref="previewModal"></preview-modal>
  </div>
</template>
<script>
import PreviewModal from "@/components/PreviewModal";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "index",
  components: {
    vueSeamlessScroll,
    PreviewModal,
  },
  props: {},

  data() {
    return {
      vedioCanPlay: false,
      templateVideo:
        // "https://atlimg.oss-cn-hangzhou.aliyuncs.com/web/home/homeTemplate.mp4",
         "http://video.antaile.net/f65a5463949a446ab1559603abaeff3a/6c8db20a05d84f2094110b28e9b5e309-5287d2089db37e62345123a1be272f8b.mp4",
      PATH_TO_MP4:
        // "http://video.antaile.net/f65a5463949a446ab1559603abaeff3a/6c8db20a05d84f2094110b28e9b5e309-5287d2089db37e62345123a1be272f8b.mp4",
        "https://atlimg.oss-cn-hangzhou.aliyuncs.com/web/home/homeVideo.mp4",
      screenWeight: 0, // 屏幕宽度
      screenHeight: 0, // 屏幕高度
      screenIndex: 1, //用于记录第一页面内部的顺序
      startTime: 0, // 翻屏起始时间
      endTime: 0, // 上一次翻屏结束时间
      pageNum: 0, // 一共有多少页
      main: Object,
      obj: Object,
      hallList1: [],
      hallList2: [],
      hallList3: [],
      checkedTab: 1,
      tabs: [
        { id: 1, name: "风格多样化" },
        { id: 2, name: "在线纪念功能" },
        { id: 3, name: "多端展示" },
      ],
      deviceType: null,
    };
  },
  mounted() {
    if (this.common.isMobile()) {
      this.deviceType = 1;
    } else {
      this.deviceType = 2;
    }
    this.main = document.getElementById("main");
    this.obj = document.getElementsByTagName("div");
    // for (let i = 0; i < this.obj.length; i++) {
    //   if (this.obj[i].className.indexOf("page") != -1) {
    //     this.obj[i].style.height = this.screenHeight + "px";
    //   }
    // }
    this.getData();
    this.screenWeight = document.documentElement.clientWidth;
    this.screenHeight = document.documentElement.clientHeight;
    const that = this;
    window.onresize = () => {
      return (() => {
        that.screenWeight = document.documentElement.clientWidth;
        that.screenHeight = document.documentElement.clientHeight;
      })();
    };
  },
  methods: {
    //创建会员中心
    createHall(){
      if(localStorage.getItem('Atoken')){
        window.open("https://www.atl-life.com/userCenter/?token=" + localStorage.getItem('Atoken'));
      //  window.open("http://192.168.31.25:9528/index?token=" + localStorage.getItem('Atoken'));
      //  window.open("http://192.168.31.25:8082/index?token=" + localStorage.getItem('Atoken'));
      window.close();
      }else{
        this.$router.push('/login')
      }
      
    },
    showPreviewModal(item) {
      this.$refs.previewModal.show(item.id);
      // if (item.device == 1) {
      //   this.$refs.previewModal.show(item.id);
      // } else {
      //   this.$router.push({
      //     path: "/preview",
      //     query: { id: item.id },
      //   });
      // }
    },
    getData() {

      this.$store
        .dispatch("selectOfficialWebsiteAtlTime", {
          // isCommend: 1,
          isFindCommend:1,
          livingState:1,
          page: { pageNum: 1, pageSize: 15 },
        })
        .then((res) => {
          this.hallList1 = res.data.list;
        })
        .catch(() => {});
      this.$store
        .dispatch("selectOfficialWebsiteAtlTime", {
          // isCommend: 1,
           isFindCommend:1,
          livingState:1,
          page: { pageNum: 2, pageSize: 15 },
        })
        .then((res) => {
          this.hallList2 = res.data.list;
        })
        .catch(() => {});
      this.$store
        .dispatch("selectOfficialWebsiteAtlTime", {
          //  isCommend: 1,
           isFindCommend:1,
          livingState:1,
          page: { pageNum: 3, pageSize: 15 },
        })
        .then((res) => {
          this.hallList3 = res.data.list;
        })
        .catch(() => {});
    },
    scrollFun(event) {
      this.startTime = new Date().getTime();
      // mousewheel事件中的 “event.wheelDelta” 属性值：返回的如果是正值说明滚轮是向上滚动
      // DOMMouseScroll事件中的 “event.detail” 属性值：返回的如果是负值说明滚轮是向上滚动
      let delta = event.detail || -event.wheelDelta;
      // 如果当前滚动开始时间和上次滚动结束时间的差值小于1.5s，则不执行翻页动作，这样做是为了实现类似节流的效果
      if (this.startTime - this.endTime > 500) {
        if (delta > 0 && this.screenIndex < 4) {
          this.screenIndex++;
        }
        if (delta < 0 && this.screenIndex > 1) {
          this.screenIndex--;
        }
        if (this.screenIndex <= 4) {
          event.preventDefault();
        }
        // 本次翻页结束，记录结束时间，用于下次判断
        this.endTime = new Date().getTime();
      }
    },
    changeTab(id) {
      this.checkedTab = id;
      this.vedioCanPlay = false;
    },
    canplay() {
      this.vedioCanPlay = true;
    },
    goTimePeriod() {
      this.$router.push({
        name: "timePeriod",
      });
    },
  },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 1,
      };
    },
    optionRight() {
      return {
        direction: 3,
        limitMoveNum: 1,
      };
    },
    // 计算属性的 getter
    // videoSource: function () {
    //   // `this` 指向 vm 实例
    //   let url = "";
    //   let newUrlHtml=''
    //   // if (this.checkedTab == 1) {
    //   //   url =
    //   //     // "https://atlimg.oss-cn-hangzhou.aliyuncs.com/web/home/homeTemplate.mp4";
    //   //     "@/assets/images/Multiterminal.jpg"
    //   //     newUrlHtml=`<img
    //   //     src="${url}"
    //   //     alt=""
    //   //     class="small"
    //   //     :style="'height:' + screenHeight + 'px'"
    //   //   />`

    //   // } else 
    //   if (this.checkedTab == 2) {
    //     url = this.PATH_TO_MP4;
    //     newUrlHtml=`<video autoplay loop muted width="100%" height="100%" style="object-fit: cover"><source src="${url}" type="video/mp4"></video>`
    //     // return `<video autoplay loop muted width="100%" height="100%" style="object-fit: cover"><source src="${url}" type="video/mp4"></video>`;
    //   }
    //   // return `<video autoplay loop muted width="100%" height="100%" style="object-fit: cover"><source src="${url}" type="video/mp4"></video>`;
    //   return newUrlHtml
    // },
  },
};
</script>

<style lang="scss" scoped>
.root {
  width: 100%;
  background: #070d1d;
}
.bg-star {
  background: url("~@/assets/images/spot.png") no-repeat;
  // background-size: 100% 100%;
  position: relative;
}
.bg-image {
  background: url("~@/assets/images/BG.png") no-repeat;
  background-size: 100% 100%;
}
.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  overflow: scroll;
}
::-webkit-scrollbar {
  display: none; /*ChromeSafari*/
}

.show-video {
  width: 70%;
  height: 60%;
  max-width: 800px;
  max-height: 600px;
  border-radius: 30px;
  position: relative;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  perspective: 1200px;
  .video {
    object-fit: fill;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    transform: translate3d(0px, 0px, 0px) rotateX(59.9972deg);
    &.animate-video {
      transform: translate(0px, 0px);
      -webkit-animation-duration: 0.5s;
      animation-duration: 0.5s;
      -webkit-animation-name: fallVideo;
      animation-name: fallVideo;
    }
  }
  .show-url {
    width: 35%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    border: 10px solid #000;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .show-border {
    width: 62%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border: 10px solid #000;
  }
}

.show-title {
  // font-weight: bold;
  text-align: center;
  padding: 15% 0 50px;
  font-size: 40px;
  color: #fff;
  box-sizing: border-box;
  letter-spacing: 10px;
  line-height: 70px;
  > p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p:last-child {
    font-size: 16px;
    color: #cccccc;
    letter-spacing: 3px;
    line-height: 40px;
    // margin-top: 10px;
  }
}
.circle {
  width: 5px;
  height: 5px;
  background-color: #ccc;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.show-mask {
  width: 100%;
  height: 100%;
  background: rgba(29, 26, 26, 0.85);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  &.animate-hidden {
    opacity: 0;
  }
}
.mask-img {
  width: 50%;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  // transform: translate(-50%, -50%);
  margin-left: -25%;
  margin-top: -150px;
  z-index: 2;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: 3px;
  .gray-color {
    padding: 5px 0;
  }
  p:first-child {
    font-size: 40px;
    color: #ccc;
    letter-spacing: 5px;
    line-height: 70px;
  }
  p:nth-child(2) {
    font-size: 30px;
    letter-spacing: 5px;
    color: #ccc;
    margin-bottom: 35px;
  }
}
.show-scrollword {
  width: 60%;
  margin: 100px auto 120px;
  text-align: center;
  color: #cccccc;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p:first-child {
    margin-bottom: 10px;
    color: #fff;
    font-size: 40px;
  }
  p:nth-child(2) {
    font-size: 40px;
  }
  p:last-child {
    text-align: left;
    margin-top: 40px;
    width: 80%;
  }
}
.scroll-table {
  width: 60%;
  // height: 650px;
  padding: 20px 0;
  background: #202020;
  border-radius: 20px;
  .show-scrollTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .title {
    color: #ffffff;
    padding: 0 30px 10px;
    font-size: 18px;
    text-align: left;
  }
  .scroll-more {
    position: relative;
    padding-right: 10px;
    .circle {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin-right: 20px;
      background: #f6c943;
    }
    .show-more {
      position: absolute;
      bottom: 15px;
      right: 0px;
      z-index: 1;
      width: 60px;
      height: 50px;
      color: #ffffff;
      font-size: 14px;
      background: url("~@/assets/images/more.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      line-height: 42px;
      letter-spacing: 2px;
    }
  }
  // 动画效果
  .seamless-warp2 {
    overflow: hidden;
    width: 100%;
    ul.item {
      display: flex;
      align-items: center;
      margin-top: 10px;
      li {
        img {
          width: 140px;
          height: 160px;
          // border-radius: 25px;
          margin-right: 10px;
        }
      }
    }
  }
}
.tab-mask {
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  width: 100%;
  height: 50%;
  background-image: linear-gradient(
    to top,
    #070d1d,
    rgba(11, 11, 20, 0.85) 25%,
    rgba(11, 11, 20, 0.45) 50%,
    rgba(11, 11, 20, 0)
  );
}
.mask-left {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30%;
  height: 100%;
  background-image: linear-gradient(
    to top,
    #070d1d,
    rgba(11, 11, 20, 0.85) 25%,
    rgba(11, 11, 20, 0.45) 50%,
    rgba(11, 11, 20, 0)
  );
}
.mask-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  height: 100%;
  background-image: linear-gradient(
    to top,
    #070d1d,
    rgba(11, 11, 20, 0.85) 25%,
    rgba(11, 11, 20, 0.45) 50%,
    rgba(11, 11, 20, 0)
  );
}
.video-mask {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 30%;
  background-image: linear-gradient(
    to top,
    #070d1d,
    rgba(11, 11, 20, 0.85) 25%,
    rgba(11, 11, 20, 0.45) 50%,
    rgba(11, 11, 20, 0)
  );
}
.show-tab {
  width: 100%;
  height: 100%;
  position: relative;
  span {
    width: 100%;
    object-fit: cover;
  }
  // background: no-repeat center bottom / 100% 100%;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .small {
    width: 100%;
    // z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    object-fit: cover;
  }

  .my-tab {
    width: 40%;
    position: absolute;
    left: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 30px;
    z-index: 1;

    ul {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      border-bottom: 2px solid #fff;
    }
    li {
      padding: 20px 10px;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      &.special-color::after {
        content: "";
        // width: 20%;
        height: 2px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        transition: 1s all linear;
        background-image: -webkit-gradient(
          linear,
          left top,
          right top,
          from(#b8832d),
          to(#f6c943)
        );
        background-image: -webkit-linear-gradient(top, #b8832d, #f6c943);
        background-image: -moz-linear-gradient(top, #b8832d, #f6c943);
        background-image: linear-gradient(to bottom, #b8832d, #f6c943);
        color: linear-gradient(to bottom, #b8832d, #f6c943);
        border-bottom: 1px solid linear-gradient(to bottom, #b8832d, #f6c943);
      }
    }
    .show-tip {
      font-size: 14px;
      color: #ffffff;
      margin: 20px;
      text-align: left;
    }
  }
}
.titleShow {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: titleShow;
  animation-name: titleShow;
}
.titleHidden {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: titleHidden;
  animation-name: titleHidden;
}
.scaleLeftShow {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: scaleLeftShow;
  animation-name: scaleLeftShow;
}
.scaleRightShow {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: scaleRightShow;
  animation-name: scaleRightShow;
}
.scaleLeftHidden {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: scaleLeftHidden;
  animation-name: scaleLeftHidden;
}
.opacityShow {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: opacityShow;
  animation-name: opacityShow;
}
.opacityHidden {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: opacityHidden;
  animation-name: opacityHidden;
}
.scaleRightHidden {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: scaleRightHidden;
  animation-name: scaleRightHidden;
}
.rightLeft {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: rightLeft;
  animation-name: rightLeft;
}
.leftRight {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: leftRight;
  animation-name: leftRight;
}
.fallVideo {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: fallVideo;
  animation-name: fallVideo;
}
.wordShow {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: wordShow;
  animation-name: wordShow;
}
@keyframes wordShow {
  from {
    opacity: 0;
    -webkit-transform: translate(0px, 52px);
    transform: translate(0px, 52px);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  to {
    // transform-origin: center bottom;
    -webkit-transform: translate(0px, 0px);
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes fallVideo {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px) rotateX(59.9972deg);
    transform: translate3d(0px, 0px, 0px) rotateX(59.9972deg);
    transform-origin: center bottom;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  to {
    // transform-origin: center bottom;
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

@keyframes titleShow {
  from {
    -webkit-transform: translate(0px, -208.333px);
    transform: translate(0px, -208.333px);

    // transform-origin: center bottom;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  to {
    // transform-origin: center bottom;
    -webkit-transform: translate3d(0px, 93.7356px, 0px);
    transform: translate3d(0px, 93.7356px, 0px);
  }
}
@keyframes opacityShow {
  from {
    opacity: 0;
    // transform-origin: center bottom;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  to {
    opacity: 1;
  }
}
@keyframes opacityHidden {
  from {
    opacity: 1;
    // transform-origin: center bottom;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  to {
    opacity: 0;
  }
}
@keyframes titleHidden {
  from {
    -webkit-transform: translate3d(0px, 93.7356px, 0px);
    transform: translate3d(0px, 93.7356px, 0px);
    // transform-origin: center bottom;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  to {
    // transform-origin: center bottom;
    -webkit-transform: translate(0px, -208.333px);
    transform: translate(0px, -208.333px);
  }
}
@keyframes scaleLeftShow {
  from {
    -webkit-transform: translate3d(-692px, 0px, 576.667px) scale(1.3, 1.3);
    transform: translate3d(-692px, 0px, 576.667px) scale(1.3, 1.3);
    // transform-origin: center bottom;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  to {
    // transform-origin: center bottom;
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
@keyframes scaleRightShow {
  from {
    -webkit-transform: translate3d(500px, 0px, 416.667px) scale(1.3, 1.3);
    transform: translate3d(500px, 0px, 416.667px) scale(1.3, 1.3);
    // transform-origin: center bottom;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  to {
    // transform-origin: center bottom;
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
@keyframes scaleLeftHidden {
  from {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
    // transform-origin: center bottom;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  to {
    -webkit-transform: translate3d(-692px, 0px, 576.667px) scale(1.3, 1.3);
    transform: translate3d(-692px, 0px, 576.667px) scale(1.3, 1.3);
    // transform-origin: center bottom;
  }
}
@keyframes scaleRightHidden {
  from {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
    // transform-origin: center bottom;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  to {
    -webkit-transform: translate3d(500px, 0px, 416.667px) scale(1.3, 1.3);
    transform: translate3d(500px, 0px, 416.667px) scale(1.3, 1.3);
    // transform-origin: center bottom;
  }
}
// @-webkit-keyframes flipInX {
//   from {
//     -webkit-transform: perspective(1200px) rotate3d(1, 0, 0, 50deg);
//     transform: perspective(1200px) rotate3d(1, 0, 0, 50deg);
//     -webkit-animation-timing-function: linear;
//     animation-timing-function: linear;
//   }
//   50% {
//     -webkit-transform: perspective(1200px) rotate3d(1, 0, 0, 30deg);
//     transform: perspective(1200px) rotate3d(1, 0, 0, 30deg) ;
//   }
//   to {
//     -webkit-transform: perspective(1200px) ;
//     transform: perspective(1200px) ;
//   }
// }

@keyframes flipInX {
  from {
    -webkit-transform: perspective(1200px) rotate3d(1, 0, 0, 50deg) scale(1);
    transform: perspective(1200px) rotate3d(1, 0, 0, 50deg) scale(1);
    // transform-origin: center bottom;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  to {
    // transform-origin: center bottom;
    -webkit-transform: perspective(1200px) scale(1.2);
    transform: perspective(1200px) scale(1.2);
  }
}

.show-moretitle {
  padding: 8% 0 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p:first-child {
    font-size: 40px;
    color: #fff;
    margin-bottom: 15px;
  }
  p:last-child {
    font-size: 40px;
  }
}
.show-more {
  display: flex;
  align-items: flex-start;
  width: 80%;
  > div {
    width: calc(33.3% - 30px);
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    img {
      width: 76px;
      height: 76px;
      margin-bottom: 20px;
    }
    p {
      color: #ccc;
      font-size: 18px;
    }
    .show-subtitle {
      font-size: 30px;
      margin-bottom: 20px;
      color: #ffffff;
    }
  }
}

.show-btn {
  background: #bb862e;
  border-radius: 51px;
  padding: 15px 70px;
  margin: 80px auto;
  color: #ffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p:first-child {
    font-size: 30px;
    margin-bottom: 5px;
    cursor: pointer;
  }
  // p:last-child {
  //   font-size: 14px;
  // }
}

.gray-color {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(215, 215, 215, 0.85)),
    to(rgba(255, 255, 255, 0.85))
  );
  background-image: -webkit-linear-gradient(
    left,
    rgba(215, 215, 215, 0.85),
    rgba(255, 255, 255, 0.85)
  );
  background-image: -moz-linear-gradient(
    left,
    rgba(215, 215, 215, 0.85),
    rgba(255, 255, 255, 0.85)
  );
  background-image: linear-gradient(
    to right,
    rgba(215, 215, 215, 0.85),
    rgba(255, 255, 255, 0.85)
  );
  color: linear-gradient(
    to right,
    rgba(215, 215, 215, 0.85),
    rgba(255, 255, 255, 0.85)
  );
  border-bottom: 1px solid
    linear-gradient(
      to right,
      rgba(215, 215, 215, 0.85),
      rgba(255, 255, 255, 0.85)
    );
}
</style>

